<template>
    <section>
        <HeaderImage :Image="Image" />
        <v-container>
            <v-row class="text-center" id="notes">
                <v-col class="mb-0">
                    <div class="text">
                        <span>N</span>
                        <span>O</span>
                        <span>T</span>
                        <span>E</span>
                        <span>S</span>
                    </div>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="7" sm="6">
                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="search">
                        <v-text-field
                            v-model="keyword"
                            :rules="searchRules"
                            label="Search Keyword"
                            required
                        ></v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="1" sm="1" class="align-center">
                    <v-btn fab color="primary white--text" small @click="search">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn
                        class="hidden-xs-only"
                        fab
                        color="red white--text ml-2"
                        small
                        @click.stop="dialog = true"
                    >
                        <v-icon>mdi-help</v-icon>
                    </v-btn>
                </v-col>
                <v-dialog v-model="dialog" max-width="600">
                    <v-card>
                        <v-card-title class="headline">How To Use Search</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="mt-2" align="justify">
                            <div class="markdown-body">
                                To filter by
                                <strong>TAGS</strong>:
                                <p>
                                    <code>tags:keyword to search</code>
                                </p>
                                To filter by <strong>TITLE</strong>:
                                <p>
                                    <code>title:keyword to search</code>
                                </p>
                                To filter by <strong>CATEGORY</strong>:
                                <p>
                                    <code>category:keyword to search</code>
                                </p>
                                You can <strong>combine</strong> the filter like this:
                                <p>
                                    <code>tags:keyword tags title:keyword title</code>
                                </p>
                                You can also use <code>LOGICAL SEARCH</code> for each filter like
                                this:
                                <ul>
                                    <li><code>OR</code> Logic use <code>|</code>:</li>
                                    <code>tags:keyword | keyword 2 | keyword 3</code>
                                    <li><code>AND</code> Logic use <code>&&</code>:</li>
                                    <code>tags:keyword && keyword 2 && keyword 3</code>
                                    <li>Logic Combination:</li>
                                    <code>tags:(keyword && keyword) | (keyword && keyword)</code
                                    ><br />
                                    <code>title:(keyword | keyword) && (keyword | keyword)</code>
                                    <br />
                                    <code>category:(keyword && (keyword | keyword))</code>
                                    <li><strong>No Complex Logic Allowed</strong></li>
                                </ul>
                                <p>
                                    No need to use <code>Quote</code> for keyword with space.
                                    Everything after the filter until the next filters will be
                                    considered as the keyword of the first filter.
                                </p>
                                If you did not specify the filter, it will use the default filter
                                which is <code>title</code>. You can trigger the search by press
                                <code>enter</code> or click the search icon.
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <NotesCard :notes="notes" :skeleton="skeleton" :nodata="nodata" :roles="roles" />
            <v-row class="justify-center mt-5 mb-3">
                <v-btn
                    color="red white--text"
                    @click="loadMore"
                    v-show="notes.length < total && !nodata && !cached_notes"
                >
                    <v-icon>mdi-reload</v-icon>Load More
                </v-btn>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import HeaderImage from "@/components/HeaderImage.vue";
import NotesCard from "@/components/NotesCard.vue";
import "@/assets/css/markdown.css";
import axios from "axios";

export default {
    name: "Notes",
    components: {
        HeaderImage,
        NotesCard
    },
    props: ["roles"],
    data: () => ({
        Image: "/assets/img/notes.jpg",
        dialog: false,
        skeleton: false,
        nodata: false,
        cached_notes: false,
        keyword: "",
        valid: true,
        searchRules: [],
        notes: [],
        filter: {
            tags: [],
            title: [],
            category: []
        },
        count: 9,
        total: 0
    }),
    watch: {
        keyword(value) {
            if (value == "") {
                this.filter.tags = [];
                this.filter.title = [];
                this.filter.category = [];
            }
            let v = /^[a-zA-Z0-9:" ()&|]*$/.test(value);
            let nestedLogic = /(\(([\w &|]*)\(([\w &|]*)\(|\)([\w &|]*)\)([\w &|]*)\))/g.test(
                value
            );
            if (v) {
                this.searchRules = [];
                if (!nestedLogic) {
                    this.searchRules = [];
                } else {
                    this.searchRules = ["Only simple keyword accepted!"];
                }
            } else {
                this.searchRules = ["Bad Character Detected!"];
            }
        }
    },
    methods: {
        async search() {
            if (this.valid) {
                this.notes = [];
                await this.parseKeyword();
                this.nodata = false;
                this.cached_notes = false;
                this.skeleton = true;
                let resp = await axios.post(
                    "/api/count/notes",
                    { filter: this.filter },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                );
                this.total = resp.data.count;
                if (this.total == 0) {
                    this.skeleton = false;
                    this.nodata = true;
                    sessionStorage.clear();
                } else {
                    let response = await axios.post(
                        "/api/notes",
                        { filter: this.filter, start: 0, count: this.count },
                        {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        }
                    );
                    this.skeleton = false;
                    this.notes = response.data;
                    sessionStorage.setItem("TKNotes", JSON.stringify(this.notes));
                    sessionStorage.setItem("keyword", this.keyword);
                }
            }
        },
        async loadMore() {
            if (this.valid) {
                this.nodata = false;
                this.cached_notes = false;
                this.skeleton = true;
                let response = await axios.post(
                    "/api/notes",
                    { filter: this.filter, start: this.notes.length, count: this.count },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                );
                this.skeleton = false;
                this.notes = this.notes.concat(response.data);
                sessionStorage.setItem("TKNotes", JSON.stringify(this.notes));
            }
        },
        parseKeyword() {
            this.filter.tags = [];
            this.filter.category = [];
            this.filter.title = [];
            let regexFilter = /(tags:|title:|category:)([a-zA-Z0-9 "|&()]+)(?![a-zA-Z:])/gi;
            let regexKeyword = /((([\w]|\([\w& ]+\))(\||))+)/gi;
            let strmatch = this.keyword.match(regexFilter);
            let rest = this.keyword.replace(regexFilter, "");
            if (strmatch) {
                for (let key of strmatch) {
                    key = key.replace(/\s*([:"|&]+)\s/g, "$1");
                    key = key.replace('"', "");
                    key = key.split(":");
                    key[1] = key[1].match(regexKeyword);
                    this.filter[key[0]] = key[1];
                }
            }

            if (rest) {
                rest = rest.replace(/\s*([:"|&]+)\s/g, "$1");
                if (this.filter.title.length == 0) {
                    this.filter.title.push(rest.trim());
                }
            }
        }
    },
    async created() {
        let response = await axios.post(
            "/api/count/notes",
            {},
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );
        this.total = response.data.count;
        let localNotes = sessionStorage.getItem("TKNotes");
        let key = sessionStorage.getItem("keyword");
        if (localNotes) {
            this.notes = JSON.parse(localNotes);
            if (key) {
                this.cached_notes = true;
                this.keyword = key;
            }
        } else {
            this.loadMore();
        }
    }
};
</script>

<style lang="scss" scoped>
.text {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    transform: translateY(-50%);
    text-align: center;
    transition: transform 0.3s ease-in-out;
    font-size: 48px;
    font-family: "Times New Roman", Times, serif;

    &:hover {
        transform: rotateX(35deg), translateY(-50%);
        span {
            color: #ccc;
            &:nth-child(odd) {
                transform: skewY(15deg);
            }
            &:nth-child(even) {
                transform: skewY(-15deg);
                background-color: #f9f9f9;
                color: #a6a6a6;
            }
        }
    }

    > span {
        display: block;
        background-color: #fff;
        width: 120px;
        height: 120px;
        line-height: 120px;
        transition: transform 0.3s ease-in-out, color 0.3s ease-in-out,
            background-color 0.3s ease-in-out;
        box-shadow: 0 40px 50px rgba(0, 0, 0, 0.1);
        &:first-child {
            border-radius: 5px 0 0 5px;
        }
    }
}
</style>
